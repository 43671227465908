import {
  ArrowRightEndOnRectangleIcon,
  ChartBarSquareIcon,
  CreditCardIcon,
  DocumentIcon,
  RectangleGroupIcon,
  ShareIcon,
  UserIcon
} from '@heroicons/react/24/outline'
import PricingPlansIcon from '~/icons/pricing-plans'
import type { MenuRBAC } from '~/routes/app/types'
import type { RemixLinkProps } from '@remix-run/react/dist/components'
import type { ComponentType, RefAttributes } from 'react'

type Props = {
  label: string
  icon: ComponentType<any>
  link: RemixLinkProps & RefAttributes<HTMLAnchorElement>
  rbac?: MenuRBAC
}

// Note that the item is shown when at least one of the roles is present
const menuItems: Props[] = [
  {
    label: 'Account',
    icon: UserIcon,
    link: { to: '/my-routescanner/account-details' }
  },
  {
    label: 'Pricing',
    icon: PricingPlansIcon,
    link: { to: '/app/pricing' }
  },
  {
    label: 'Billing',
    icon: CreditCardIcon,
    link: { to: '/app/billing' }
  },
  {
    label: 'Emission reports',
    icon: ChartBarSquareIcon,
    link: { to: '/app/emission-reports' }
  },
  {
    label: 'Quote history',
    icon: DocumentIcon,
    link: { to: '/my-routescanner/quote-history' }
  },
  {
    label: 'Schedule builder',
    icon: RectangleGroupIcon,
    // The link is empty because this comes from the API, we add it later
    link: { to: '', target: '_blank' },
    rbac: { action: 'read', subject: 'schedule-builder' }
  },
  {
    label: 'Co-sharing overview',
    icon: ShareIcon,
    link: { to: '/my-routescanner/co-sharing/overview', prefetch: 'intent' },
    rbac: { action: 'read', subject: 'co-sharing' }
  },
  {
    label: 'Log out',
    icon: ArrowRightEndOnRectangleIcon,
    link: { to: '/logout' }
  }
]

export default menuItems
