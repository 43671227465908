import { memo, type SVGProps } from 'react'

const icon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    aria-hidden
    focusable={false}
    data-icon="shipments-icon"
    className="size-6"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path stroke="currentColor" strokeWidth="1.5" d="M12 19H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2z" />
    <path stroke="currentColor" strokeLinecap="round" strokeWidth="1.5" d="M7 8.5v7M17 8.5v7M12 8.5v7" />
  </svg>
))

export default icon
