import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { Link, NavLink, useLoaderData, useLocation } from '@remix-run/react'
import classnames from 'classnames'
import { Fragment, useContext } from 'react'
import userMenuItems from '~/components/navigation/user-menu-items'
import ApiIcon from '~/icons/navbar/api'
import SvgLogo from '~/icons/svg-logo'
import type { loader } from '~/routes/app/route'
import { AbilityContext } from '~/services/rbac'
import { Button } from '../design-system/button'
import navigationItems from './navigation-items'
import getToolNavigationItems from './navigation-items-tool'

export default function NavigationMobile({ sidebarOpen, setSidebarOpen }: { sidebarOpen: boolean; setSidebarOpen: (open: boolean) => void }) {
  const { profile, scheduleBuilderUrl } = useLoaderData<typeof loader>()
  const ability = useContext(AbilityContext)

  const location = useLocation()
  const returnTo = encodeURIComponent(location.pathname + location.search)
  const loginUrl = returnTo ? `/login?returnTo=${returnTo}` : '/login'

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog className="relative z-[70] xl:hidden" onClose={setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full">
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" className="-m-2.5 rounded-full bg-white/20 p-1" onClick={() => setSidebarOpen(false)}>
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>

              <div className="flex grow flex-col overflow-y-auto bg-white px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <Link reloadDocument to="/">
                    <SvgLogo className="h-4 fill-black hover:fill-brand-darkest" />
                  </Link>
                </div>

                <nav className="flex flex-1 flex-col">
                  {!profile && (
                    <div className="flex flex-row gap-x-2">
                      <Button variant="outline" size="lg" asChild>
                        <Link to={loginUrl}>Log in</Link>
                      </Button>

                      <Button variant="secondary" size="lg" asChild>
                        <Link to="/register">Get started for free</Link>
                      </Button>
                    </div>
                  )}

                  <ul className="flex flex-1 flex-col gap-y-4">
                    {profile &&
                      getToolNavigationItems(ability).map(({ items, groupName }) => {
                        return (
                          <Fragment key={groupName}>
                            <li className="mb-1 mt-3 text-left text-xs font-bold uppercase text-brand-darkest">{groupName}</li>

                            {items.map(item => {
                              return (
                                <li key={item.name}>
                                  <NavLink
                                    reloadDocument
                                    to={item.to}
                                    className={({ isActive }) => {
                                      // Because our routes are not properly hierarchical, we need to override the active state for some items
                                      const active = item.overrideActive ? location.pathname.startsWith(item.overrideActive) : isActive

                                      return classnames(
                                        'relative flex items-center rounded text-base',
                                        'flex-row justify-start gap-x-2 pl-3 pr-2 text-left',
                                        {
                                          'font-bold text-brand-darkest': active,
                                          'text-brand-dark': !active
                                        }
                                      )
                                    }}>
                                    {({ isActive }) => {
                                      // Because our routes are not properly hierarchical, we need to override the active state for some items
                                      const active = item.overrideActive ? location.pathname.startsWith(item.overrideActive) : isActive

                                      return (
                                        <>
                                          {active && <div className="absolute inset-y-0 left-0 w-1 rounded bg-brand-primary-accent" />}
                                          <item.icon className="size-5 shrink-0" aria-hidden="true" />
                                          <span>{item.name}</span>
                                        </>
                                      )
                                    }}
                                  </NavLink>
                                </li>
                              )
                            })}
                          </Fragment>
                        )
                      })}

                    {profile && (
                      <>
                        <hr />

                        <NavLink
                          reloadDocument
                          to="/app/api"
                          className={({ isActive }) => {
                            return classnames('relative flex items-center rounded text-base', 'flex-row justify-start gap-x-2 pl-3 pr-2 text-left', {
                              'font-bold text-brand-darkest': isActive,
                              'text-brand-dark': !isActive
                            })
                          }}>
                          {({ isActive }) => {
                            return (
                              <>
                                {isActive && <div className="absolute inset-y-0 left-0 w-1 rounded bg-brand-primary-accent" />}
                                <ApiIcon className="size-5 shrink-0" aria-hidden="true" />
                                <span className="lg:w-20">API</span>
                              </>
                            )
                          }}
                        </NavLink>

                        <hr />

                        <Disclosure>
                          <Disclosure.Button as="li" className="flex cursor-pointer flex-row items-center gap-x-2 py-1 pl-3">
                            <img src={profile.picture} className="size-5 rounded-full" alt="" />
                            <div className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold">
                              {profile.organizationDisplayName || profile.displayName}
                            </div>
                            <ChevronDownIcon className="size-4" aria-hidden="true" />
                          </Disclosure.Button>

                          <Disclosure.Panel>
                            <ul className="-mt-2">
                              {userMenuItems
                                .filter(item => {
                                  if (item.rbac) {
                                    return ability.can(item.rbac.action, item.rbac.subject)
                                  }

                                  return true
                                })
                                .map(item => {
                                  const linkTo = item.label === 'Schedule builder' ? scheduleBuilderUrl : item.link.to

                                  return (
                                    <Link
                                      key={item.label}
                                      {...item.link}
                                      reloadDocument
                                      to={linkTo}
                                      className={classnames('m-1 flex flex-row items-center gap-x-2 rounded px-2 py-2 text-sm')}>
                                      <item.icon className="size-5" />
                                      {item.label}
                                    </Link>
                                  )
                                })}
                            </ul>
                          </Disclosure.Panel>
                        </Disclosure>
                      </>
                    )}

                    <li className="mt-4">
                      <ul className="space-y-px font-bold text-white">
                        <li className="-mx-6 flex flex-row justify-between bg-brand-darkest px-4 py-4">
                          <NavLink reloadDocument to="/" className="">
                            Home
                          </NavLink>
                        </li>

                        {navigationItems.map(({ title, items, subItems }) => {
                          const allItems = [...items, ...(subItems || [])]

                          return (
                            <Fragment key={title}>
                              <Disclosure>
                                <Disclosure.Button
                                  as="li"
                                  className="-mx-6 flex cursor-pointer flex-row items-center justify-between bg-brand-darkest py-4 pl-4 pr-6">
                                  <span>{title}</span>
                                  <ChevronDownIcon className="size-4" aria-hidden="true" />
                                </Disclosure.Button>

                                <Disclosure.Panel>
                                  <ul>
                                    {allItems.map(({ title, href }) => (
                                      <li key={href}>
                                        <Link
                                          to={href}
                                          reloadDocument
                                          // target={blank ? '_blank' : '_self'}
                                          className="-mx-6 flex flex-row justify-between bg-brand-darkest px-6 py-4 text-sm">
                                          {title}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </Disclosure.Panel>
                              </Disclosure>
                            </Fragment>
                          )
                        })}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
