import { memo, type SVGProps } from 'react'

const icon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    aria-hidden
    focusable={false}
    data-icon="optimizer-icon"
    className="size-6"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.808 8.057a.75.75 0 0 1 .92-.527l3.115.849a.75.75 0 0 1 .529.915l-.823 3.122a.75.75 0 0 1-1.45-.383l.337-1.281a23.5 23.5 0 0 0-3.609 3.056.75.75 0 0 1-1.07.01L10 12.06l-3.72 3.72a.75.75 0 1 1-1.06-1.061l4.25-4.25a.75.75 0 0 1 1.06 0l1.756 1.755a25 25 0 0 1 3.508-2.85l-1.46-.398a.75.75 0 0 1-.526-.92"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M3 6.25A2.25 2.25 0 0 1 5.25 4h13.5A2.25 2.25 0 0 1 21 6.25v12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18.25z"
    />
  </svg>
))

export default icon
