import { memo, type SVGProps } from 'react'

const icon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    aria-hidden
    focusable={false}
    data-icon="direct-connections-icon"
    className="size-6"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      d="M12 20.25H5a2 2 0 0 1-2-2V8.464m9 11.786h7a2 2 0 0 0 2-2V5.75a2 2 0 0 0-2-2h-7m0 16.5V8.464m0-4.714H5a2 2 0 0 0-2 2v2.714m9-4.714v4.714m0 0H3"
    />
  </svg>
))

export default icon
