import type { Props as NavigationProps } from './navigation-popover'

type Props = Omit<NavigationProps, 'dark'>

const solutions: Props = {
  title: 'Solutions',
  items: [
    {
      title: 'Route Optimizer',
      href: '/features/route-optimizer',
      description: "Find your optimal with the world's largest network of ocean and inland schedules"
    },
    {
      title: 'Emission Reporting',
      href: '/features/emissions-calculation',
      description: 'Generate carbon footprint reports or CO2e values for all of your shipments'
    },
    {
      title: 'Distance & Emissions API',
      href: '/features/distance-emissions-api',
      description: 'Accurate distances and emissions values for barge, rail, truck and ocean routes'
    }
  ],
  subItems: [{ title: 'Embedded Direct Connections', href: '/features/direct-connections' }]
}

const industries: Props = {
  title: 'Industries',
  items: [
    {
      title: 'Shippers & Cargo Owners',
      href: '/industries/shippers-and-cargo-owners',
      description: 'Improve your container logistics network by finding better Routes'
    },
    {
      title: 'Freight Forwarders',
      href: '/industries/freight-forwarders',
      description: 'Acquire and retain more customers by always offering a fitting route solution'
    },
    {
      title: 'Carriers & Operators',
      href: '/industries/carriers-and-operators',
      description: 'Grow your container business by promoting your network'
    }
  ],
  subItems: [{ title: 'Ports & Terminals', href: '/features/direct-connections' }]
}

const resources: Props = {
  title: 'Resources',
  items: [
    { title: 'Connected Operators', href: '/partners', description: 'See all operators operators that can be found on Routescanner' },
    { title: 'Schedule Coverage', href: '/schedule-coverage', description: 'Global map of all container schedule routes' },
    {
      title: 'Connected Ports & Inland Hubs',
      href: '/locodes',
      description: 'An overview of all ports & inland hubs from which you can ship containers.'
    },
    { title: 'API Documentation', href: 'https://docs.routescanner.com/', description: 'Read how easy it is to set-up a connection to our API' }
  ],
  subItems: [
    { title: 'GLEC Emissions Calculation Method', href: '/glec' },
    { title: 'SIG Data Neutrality', href: '/SIG' },
    { title: 'ISO Compliance', href: '/ISO-14083' }
  ]
}

const aboutUs: Props = {
  title: 'About us',
  items: [
    { title: 'Contact us', href: '/contact', description: 'Reach out to us for any questions! We’ll be happy to talk to you' },
    { title: 'In the media', href: '/news-room', description: 'See where we are mentioned or contribute to the conversation about shipping' },
    { title: 'Blog & News', href: '/news', description: 'Read our latest blogs about improving container supply chains, and other news' },
    { title: 'Events', href: '/events', description: 'Find out where to meet us in real-life' }
  ],
  subItems: [
    { title: 'Our mission', href: '/about' },
    { title: 'Careers', href: '/careers' }
  ]
}

export default [solutions, industries, resources, aboutUs]
