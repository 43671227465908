import { memo } from 'react'
import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.538 5.5v13h6.334v-13H8.538Zm-1.5-.167H4.462c-.737 0-1.334.597-1.334 1.334v10.666c0 .737.597 1.334 1.334 1.334h2.576c0 .736.597 1.333 1.334 1.333h6.666c.737 0 1.334-.597 1.334-1.333h2.666c.737 0 1.334-.597 1.334-1.334V6.667c0-.737-.597-1.334-1.334-1.334h-2.666c0-.736-.597-1.333-1.334-1.333H8.372c-.737 0-1.334.597-1.334 1.333Zm9.482 1.5v10.334h2.352V6.833H16.52Zm-9.54 0v10.334H4.628V6.833H6.98Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="transparent" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

const Memo = memo(SvgComponent)

export default Memo
