import Builder from '~/icons/navbar/builder'
import DirectConnections from '~/icons/navbar/direct-connections'
import Optimizer from '~/icons/navbar/optimizer'
import Shipments from '~/icons/navbar/shipments'
import TradeLanes from '~/icons/navbar/trade-lanes'
import Upload from '~/icons/navbar/upload'
import type { MenuRBAC } from '~/routes/app/types'
import type { AppAbilities } from '~/services/rbac'
import type { ComponentType } from 'react'

type NavigationItem = {
  groupName: string

  items: Array<{
    name: string
    rbac?: MenuRBAC
    icon: ComponentType<any>
    to: string
    overrideActive: string | null
  }>
}

const items: NavigationItem[] = [
  {
    groupName: 'Overview',
    items: [
      { name: 'Trade Lanes', icon: TradeLanes, to: '/app/trade-lanes', overrideActive: null },
      { name: 'Shipments', icon: Shipments, to: '/app/shipments', overrideActive: null }
    ]
  },

  {
    groupName: 'Search',
    items: [
      { name: 'Optimizer', icon: Optimizer, to: '/app/optimizer', overrideActive: null },
      { name: 'Connectivity Comparison', icon: DirectConnections, to: '/app/connectivity-comparison', overrideActive: null }
    ]
  },

  {
    groupName: 'Data',
    items: [
      { name: 'Upload', icon: Upload, to: '/app/import', rbac: { action: 'read', subject: 'importer' }, overrideActive: null },
      { name: 'Builder', icon: Builder, to: '/app/build-tradelane', rbac: { action: 'read', subject: 'trade-lane-builder' }, overrideActive: null }
    ]
  }
]

// Filter out items that are not available for the current user
export default function getToolNavigationItems(ability: AppAbilities) {
  return (
    items
      .map(group => ({
        ...group,
        items: group.items.filter(item => {
          if (!item.rbac) {
            return true
          }

          return ability.can(item.rbac.action, item.rbac.subject)
        })
      }))
      // If the filtered group is empty, we don't show it anymore
      .filter(group => group.items.length > 0)
  )
}
