import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Link } from '@remix-run/react'
import classnames from 'classnames'
import { Fragment, useContext } from 'react'
import { Button } from '~/components/design-system/button'
import { AbilityContext } from '~/services/rbac'
import type { UserProfile } from '~/types/auth'
import userMenuItems from './user-menu-items'

type Props = {
  profile: UserProfile
  scheduleBuilderUrl: string
  forTool: boolean
}

export default function UserMenu({ profile, scheduleBuilderUrl, forTool }: Props) {
  const ability = useContext(AbilityContext)

  return (
    <Menu as="div" className="relative hidden lg:block">
      <Menu.Button as="div">
        <Button
          variant="outline"
          size="md"
          className={classnames('focus:outline-0', {
            'bg-transparent text-white ring-0 ring-brand-medium hover:ring-1 active:bg-transparent active:ring-1': forTool
          })}>
          <img src={profile.picture} className="size-5 rounded-full" alt="" />
          <div className="max-w-32 overflow-hidden text-ellipsis whitespace-nowrap font-bold">
            {profile.organizationDisplayName || profile.displayName}
          </div>
          <ChevronDownIcon className="size-3" aria-hidden="true" />
        </Button>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 z-50 mt-4 w-fit min-w-40 overflow-hidden rounded border border-brand-light bg-white text-brand-dark shadow-lg focus:outline-none">
          {userMenuItems
            .filter(item => {
              if (item.rbac) {
                return ability.can(item.rbac.action, item.rbac.subject)
              }

              return true
            })
            .map(item => {
              const linkTo = item.label === 'Schedule builder' ? scheduleBuilderUrl : item.link.to

              return (
                <Menu.Item key={item.label}>
                  {({ active }) => (
                    <Link
                      {...item.link}
                      to={linkTo}
                      className={classnames(
                        'm-1 flex flex-row items-center gap-x-2 whitespace-nowrap rounded px-2 py-2 text-sm font-bold outline outline-1 outline-transparent',
                        'transition-[outline-color] duration-300 ease-out hover:outline-brand-medium',
                        {
                          'bg-brand-lightest text-brand-darkest': active
                        }
                      )}>
                      <item.icon className="size-4 shrink-0" />
                      {item.label}
                    </Link>
                  )}
                </Menu.Item>
              )
            })}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
