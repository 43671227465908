import { Bars3Icon } from '@heroicons/react/24/outline'
import { Link, useLocation } from '@remix-run/react'
import classnames from 'classnames'
import React, { Fragment, useState } from 'react'
import { Button } from '~/components/design-system/button'
import { PADDING } from '~/components/landing-pages/constants'
import NavigationMobile from '~/components/navigation/navigation-mobile'
import RoutescannerLogo from '~/icons/svg-logo'
import type { UserProfile } from '~/types/auth'
import navigationItems from './navigation-items'
import NavigationPopover from './navigation-popover'
import UserMenu from './user-menu'

type Props = {
  forTool?: boolean
  profile?: UserProfile | null
  scheduleBuilderUrl: string
  showGoToToolButton?: boolean
}

export default function TopNav({ profile, scheduleBuilderUrl, forTool = false }: Props) {
  const location = useLocation()
  const returnTo = encodeURIComponent(location.pathname + location.search)
  const loginUrl = returnTo ? `/login?returnTo=${returnTo}` : '/login'

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const dark = forTool
  const showGoToToolButton = !forTool

  return (
    <Fragment>
      <div
        className={classnames(
          'z-[60] flex h-[56px] flex-row items-center justify-between text-sm',
          {
            'sticky inset-x-0 top-0 bg-brand-darkest text-white': forTool,
            MAX_WIDTH: !forTool
          },
          forTool ? 'px-8' : PADDING
        )}>
        <Link to="/">
          <RoutescannerLogo
            className={classnames({
              'fill-brand-darkest': !dark,
              'fill-white': dark
            })}
          />
        </Link>

        <nav className="hidden h-full flex-row items-center justify-center gap-x-4 xl:flex">
          <Link
            to="/"
            reloadDocument
            className={classnames(
              'mr-4 flex flex-row items-center rounded px-2 py-1 font-bold focus:outline-none',
              'transition-colors duration-300 ease-in-out',
              {
                'hover:bg-white/20': dark,
                'hover:bg-brand-darkest/5': !dark
              }
            )}>
            Home
          </Link>

          {navigationItems.map(popover => (
            <NavigationPopover key={popover.title} dark={dark} {...popover} />
          ))}
        </nav>

        {!profile && (
          <div className="hidden xl:flex xl:flex-row xl:items-center xl:gap-x-3">
            <Button variant="ghost" size="md" asChild>
              <Link to={loginUrl}>Log in</Link>
            </Button>

            <Button variant="outline" size="md" asChild>
              <Link to="/register">Get started for free</Link>
            </Button>
          </div>
        )}

        {profile && (
          <div className="hidden xl:flex xl:flex-row xl:items-center xl:gap-x-3">
            {showGoToToolButton && (
              <Button variant="secondary" size="md" asChild>
                <Link to="/app/optimizer">Go to Tool</Link>
              </Button>
            )}

            <UserMenu profile={profile} scheduleBuilderUrl={scheduleBuilderUrl} forTool={forTool} />
          </div>
        )}

        <div className="xl:hidden">
          <button type="button" onClick={() => setSidebarOpen(true)}>
            <Bars3Icon className="size-6" />
          </button>
        </div>
      </div>

      <NavigationMobile sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    </Fragment>
  )
}
