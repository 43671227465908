import { memo, type SVGProps } from 'react'

const icon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    aria-hidden
    focusable={false}
    data-icon="trade-lanes-icon"
    className="size-6"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      d="M8.5 9H6a2 2 0 0 1-2-2V5.429C4 4.639 4.64 4 5.429 4H11a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2zM15.5 20H13a2 2 0 0 1-2-2v-1.571c0-.79.64-1.429 1.429-1.429H18a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="1.5"
      d="M13 6.5h5a2 2 0 0 1 2 2V10a2 2 0 0 1-2 2H5.5a2 2 0 0 0-2 2v1.5a2 2 0 0 0 2 2H11"
    />
  </svg>
))

export default icon
