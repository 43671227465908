import { memo, type SVGProps } from 'react'

const icon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    aria-hidden
    focusable={false}
    data-icon="api-icon"
    className="size-6"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25"
    />
  </svg>
))

export default icon
