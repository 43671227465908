import { Popover, PopoverButton, PopoverPanel } from '@headlessuiv2/react'
import { ArrowRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { Link } from '@remix-run/react'
import classnames from 'classnames'
import React from 'react'

export type Props = {
  title: string
  dark: boolean

  items: Array<{
    title: string
    href: string
    description?: string
  }>

  subItems?: Array<{
    title: string
    href: string
  }>
}

export default function NavigationPopover({ dark, title, items, subItems }: Props) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <PopoverButton
            className={classnames(
              'inline-flex items-center gap-x-1 rounded px-2 py-1 font-bold focus:outline-none',
              'transition-colors duration-300 ease-in-out',
              {
                'hover:bg-white/20': dark,
                'hover:bg-brand-darkest/5': !dark,
                'bg-white/20': open && dark,
                'bg-brand-darkest/5': open && !dark
              }
            )}>
            <span>{title}</span>
            <ChevronDownIcon className="size-4" aria-hidden="true" />
          </PopoverButton>

          <PopoverPanel
            transition
            className="absolute left-1/2 z-50 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in">
            <div className="w-screen max-w-md flex-auto overflow-hidden rounded bg-white shadow-menu ring-1 ring-brand-light">
              <div className="flex flex-col gap-y-6 p-8">
                <div className="text-sm font-bold uppercase tracking-wide text-brand-dark">{title}</div>

                <ul className="flex flex-col gap-y-6">
                  {items.map(item => (
                    <li key={item.href} className="flex flex-col gap-y-1">
                      <Link
                        reloadDocument
                        to={item.href}
                        className="group flex flex-row items-center gap-x-1 font-bold text-brand-utility hover:text-brand-darkest">
                        {item.title}

                        <ArrowRightIcon className="hidden size-4 group-hover:block" />
                      </Link>

                      <div className="text-brand-dark">{item.description}</div>
                    </li>
                  ))}
                </ul>
              </div>

              {subItems && (
                <div className="border-t border-brand-light bg-brand-lightest p-8">
                  <ul className="flex flex-col gap-y-6">
                    {subItems.map(subItem => (
                      <li key={subItem.href}>
                        <Link reloadDocument to={subItem.href} className="text-brand-dark underline underline-offset-4 hover:text-brand-utility">
                          {subItem.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  )
}
